import {
  AddOrUpdateCustomerInCustomerIoMutation,
  AddOrUpdateCustomerInCustomerIoMutationVariables,
  IdentifyUserInCustomerIoMutation,
  IdentifyUserInCustomerIoMutationVariables,
  IdentifyUserInMixpanelMutation,
  IdentifyUserInMixpanelMutationVariables,
  IsLoggedInQuery,
  IsLoggedInQueryVariables,
  LogAnonymousEventToCustomerIoMutation,
  LogAnonymousEventToCustomerIoMutationVariables,
  LogAnonymousEventToMixpanelMutation,
  LogAnonymousEventToMixpanelMutationVariables,
  LogUserEventToCustomerIoMutation,
  LogUserEventToCustomerIoMutationVariables,
  LogUserEventToMixpanelMutation,
  LogUserEventToMixpanelMutationVariables,
} from "@/__generated__/graphql";
import {
  IDENTIFY_USER_IN_CUSTOMER_IO_MUTATION,
  IDENTIFY_USER_IN_MIXPANEL_MUTATION,
  LOG_ANONYMOUS_EVENT_TO_CUSTOMER_IO_MUTATION,
  LOG_ANONYMOUS_EVENT_TO_MIXPANEL_MUTATION,
  LOG_USER_ATTRIBUTES_CUSTOMER_IO_MUTATION,
  LOG_USER_EVENT_TO_CUSTOMER_IO_MUTATION,
  LOG_USER_EVENT_TO_MIXPANEL_MUTATION,
} from "@/api/analytics";
import { IS_LOGGED_IN_QUERY } from "@/api/auth";
import {
  BETA_ACCESS_COOKIE,
  PHIA_COOKIE_ID,
  PHIA_COOKIE_MIXPANEL_DEVICE_ID,
  PHIA_COOKIE_MIXPANEL_DISTINCT_ID,
  PLATFORM,
} from "@/constants";
import { privateRoutes } from "@/routes";
import {
  CustomerIoEventName,
  LogEventProperties,
  MixpanelEventName,
} from "@/types";
import { useMutation, useQuery } from "@apollo/client";
import { matchRoute } from "@phiaco/phia-ui/dist/util";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { useCallback, useMemo } from "react";
import packageJson from "../../../package.json";
import usePhiaPathname from "./usePhiaPathname";
import usePhiaSearchParams from "./usePhiaSearchParams";

// A custom hook for logging analytic events to Mixpanel
const useAnalytics = () => {
  const { data: isLoggedInData } = useQuery<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >(IS_LOGGED_IN_QUERY);
  const isLoggedIn = isLoggedInData?.isLoggedIn;
  const [logAnonymousEventToMixpanelMutation] = useMutation<
    LogAnonymousEventToMixpanelMutation,
    LogAnonymousEventToMixpanelMutationVariables
  >(LOG_ANONYMOUS_EVENT_TO_MIXPANEL_MUTATION);
  const [logUserEventToMixpanelMutation] = useMutation<
    LogUserEventToMixpanelMutation,
    LogUserEventToMixpanelMutationVariables
  >(LOG_USER_EVENT_TO_MIXPANEL_MUTATION);

  const [identifyUserInMixpanelMutation] = useMutation<
    IdentifyUserInMixpanelMutation,
    IdentifyUserInMixpanelMutationVariables
  >(IDENTIFY_USER_IN_MIXPANEL_MUTATION);
  const [logAnonymousEventToCustomerIoMutation] = useMutation<
    LogAnonymousEventToCustomerIoMutation,
    LogAnonymousEventToCustomerIoMutationVariables
  >(LOG_ANONYMOUS_EVENT_TO_CUSTOMER_IO_MUTATION);
  const [identifyUserInCustomerIoMutation] = useMutation<
    IdentifyUserInCustomerIoMutation,
    IdentifyUserInCustomerIoMutationVariables
  >(IDENTIFY_USER_IN_CUSTOMER_IO_MUTATION);
  const [logUserEventToCustomerIOMutation] = useMutation<
    LogUserEventToCustomerIoMutation,
    LogUserEventToCustomerIoMutationVariables
  >(LOG_USER_EVENT_TO_CUSTOMER_IO_MUTATION);
  const [addOrUpdateCustomerInCustomerIoMutation] = useMutation<
    AddOrUpdateCustomerInCustomerIoMutation,
    AddOrUpdateCustomerInCustomerIoMutationVariables
  >(LOG_USER_ATTRIBUTES_CUSTOMER_IO_MUTATION);
  const pathname = usePhiaPathname();
  const currentRoute = useMemo(() => pathname, [pathname]);

  const deviceId = Cookies.get(PHIA_COOKIE_MIXPANEL_DEVICE_ID) ?? "";
  const distinctId = Cookies.get(PHIA_COOKIE_MIXPANEL_DISTINCT_ID) ?? "";
  const phiaId = Cookies.get(PHIA_COOKIE_ID) ?? "";
  const betaAccessCookie = Cookies.get(BETA_ACCESS_COOKIE) ?? "";
  const searchParams = usePhiaSearchParams();

  const globalProperties = useMemo(() => {
    return {
      pathname: currentRoute,
      url: typeof window === "undefined" ? undefined : window.location.href,
      utm_id: searchParams.get("utm_id"),
      utm_source: searchParams.get("utm_source"),
      utm_medium: searchParams.get("utm_medium"),
      utm_campaign: searchParams.get("utm_campaign"),
      utm_term: searchParams.get("utm_term"),
      utm_content: searchParams.get("utm_content"),
      phia_id: phiaId,
    };
  }, [currentRoute, searchParams, phiaId]);

  const isPrivateRoute = matchRoute(currentRoute, privateRoutes);

  // Use this method to log an event to Mixpanel for an authed user
  const logUserEventToMixpanel = useCallback(
    ({
      name,
      properties,
    }: {
      name: MixpanelEventName;
      properties?: LogEventProperties;
    }) => {
      logUserEventToMixpanelMutation({
        variables: {
          eventName: name,
          properties: JSON.stringify({ ...globalProperties, ...properties }),
        },
      });
    },
    [globalProperties, logUserEventToMixpanelMutation]
  );

  // Use this method to log an event to Mixpanel for an authed user
  const logMixpanelEvent = useCallback(
    ({
      name,
      properties,
    }: {
      name: MixpanelEventName;
      properties?: LogEventProperties;
    }) => {
      const variables = {
        eventName: name,
        properties: JSON.stringify({ ...globalProperties, ...properties }),
      };

      const anonymousVariables = {
        ...globalProperties,
        platform: PLATFORM,
        platformVersion: packageJson.version,
        deviceId,
        distinctId,
        ...variables,
      };
      // Adding session key to sync properly with logic we are using in `getSearchLimitData`
      if (isPrivateRoute || (isLoggedIn && betaAccessCookie)) {
        logUserEventToMixpanelMutation({
          variables,
          errorPolicy: "ignore",
          onError: () => {
            logAnonymousEventToMixpanelMutation({
              variables: anonymousVariables,
            });
          },
        });
      } else {
        logAnonymousEventToMixpanelMutation({
          variables: anonymousVariables,
        });
      }
    },
    // We don't want to call this page_view more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalProperties, isLoggedIn]
  );

  // Use this method to log an event to Mixpanel for an unauthed user
  const logAnonymousEventToMixpanel = useCallback(
    ({
      name,
      properties,
    }: {
      name: MixpanelEventName;
      properties?: LogEventProperties;
    }) => {
      logAnonymousEventToMixpanelMutation({
        variables: {
          eventName: name,
          platform: PLATFORM,
          platformVersion: packageJson.version,
          deviceId,
          distinctId,
          properties: JSON.stringify({ ...globalProperties, ...properties }),
        },
      });
    },
    // We don't want to call this page_view more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalProperties]
  );

  const setMixpanelUserProperties = useCallback(
    async () => {
      await identifyUserInMixpanelMutation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Use this method to log an event to CustomerIO for an unauthed user
  // The anonymousId should be intentional and give us a way to identify the user (e.g. email)
  const logAnonymousEventToCustomerIo = useCallback(
    ({
      name,
      anonymousId,
      properties,
    }: {
      name: CustomerIoEventName;
      anonymousId: string;
      properties?: LogEventProperties;
    }) => {
      logAnonymousEventToCustomerIoMutation({
        variables: {
          eventName: name,
          anonymousId,
          properties: JSON.stringify(properties),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Use this callback for perf heavy API call
  const logClientSideUserEventToMixpanel = useCallback(
    ({
      name,
      properties,
    }: {
      name: MixpanelEventName;
      properties?: LogEventProperties;
    }) => {
      mixpanel.track(name, {
        ...globalProperties,
        platform: PLATFORM,
        platformVersion: packageJson.version,
        pathname: currentRoute,
        ...properties,
      });
    },
    // We don't want to call this page_view more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoute]
  );

  // Use this method to log an event to CustomerIO for an authed user
  const logUserEventToCustomerIo = useCallback(
    ({
      name,
      properties,
    }: {
      name: CustomerIoEventName;
      properties?: LogEventProperties;
    }) => {
      logUserEventToCustomerIOMutation({
        variables: {
          platform: PLATFORM,
          platformVersion: packageJson.version,
          eventName: name,
          properties: JSON.stringify({ pathname: currentRoute, ...properties }),
        },
      });
    },
    // We don't want to call this page_view more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoute]
  );

  // Use this method to log an event to CustomerIO for an unauthed user
  // Identifying the user via email
  const addOrUpdateCustomerInCustomerIO = useCallback(
    ({
      email,
      attributes,
      userId,
    }: {
      email: string;
      attributes?: LogEventProperties;
      userId?: string;
    }) => {
      addOrUpdateCustomerInCustomerIoMutation({
        variables: {
          email,
          platform: PLATFORM,
          platformVersion: packageJson.version,
          attributes: JSON.stringify({ pathname: currentRoute, ...attributes }),
          userId,
        },
      });
    },
    // We don't want to call this page_view more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoute]
  );

  const setCustomerIoUserProperties = useCallback(
    async () => {
      await identifyUserInCustomerIoMutation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    logMixpanelEvent,
    logUserEventToMixpanel,
    logAnonymousEventToMixpanel,
    setMixpanelUserProperties,
    logClientSideUserEventToMixpanel,
    setCustomerIoUserProperties,
    logAnonymousEventToCustomerIo,
    logUserEventToCustomerIo,
    addOrUpdateCustomerInCustomerIO,
  };
};

export default useAnalytics;
