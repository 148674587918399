"use client";

import Button from "@/components/shared/Button";
import { EXTENSION_URL } from "@/constants";
import { MixpanelEventName } from "@/types";
import cn from "classnames";
import Image from "next/image";

interface ExtensionAdProps {
  className?: string;
}

const ExtensionAd: React.FC<ExtensionAdProps> = ({ className }) => {
  return (
    <div
      id="extension-ad"
      className={cn(
        "flex h-full flex-col-reverse gap-8 pt-8 sm:gap-16 md:gap-5 xl:flex-row",
        className
      )}>
      <div className="relative flex w-[60%] flex-1 items-center sm:w-full">
        <Image
          src="https://zbypaa9oazexo3ul.public.blob.vercel-storage.com/extension-ad-woman-LqjcsFEhbJlM9i2o6BHJcQCY82QMZ2"
          width={383.5}
          height={371}
          alt="Picture of the face of a woman"
          className="rounded-sm shadow-deepBelow"
        />
        <Image
          src="https://zbypaa9oazexo3ul.public.blob.vercel-storage.com/extension-ad-screenshot-NkiQQSov3gOHQFNYxC1MSGyCw96mMr"
          width={383.5}
          height={371}
          alt="Example of Phia chrome extension"
          className="absolute translate-x-1/2 translate-y-[2.5rem]"
        />
      </div>
      <div className="flex flex-1 flex-col justify-center gap-4 sm:gap-6">
        <div className="para-md flex">Get the Phia Extension</div>
        <div className="display-xs inline sm:display-sm md:display-md md:font-[3.75rem]">
          Take the Phia
          <br />
          Extension with you
          <br />
          <i>anywhere you shop</i>
        </div>
        <div className="heading-sans-xs sm:heading-sans-sm">
          The Phia browser extension is your personalized AI Buying tool that
          shows you cheaper resale alternatives while you shop.
        </div>
        <Button
          variant="primary"
          onClick={() => window.open(EXTENSION_URL, "_blank")}
          eventName={MixpanelEventName.LANDING_PAGE_EXTENSION_AD_CLICKED}>
          Get the Phia extension
        </Button>
      </div>
    </div>
  );
};

export default ExtensionAd;
