import { MixpanelEventName } from "@/types";
import { useCallback, useEffect } from "react";
import useAnalytics from "./useAnalytics";
import { useNavigationEvents } from "./useNavigationEvents";

export default function useRouteChangeTracker() {
  const url = useNavigationEvents();
  const { logMixpanelEvent } = useAnalytics();

  const handleRouteChange = useCallback(() => {
    logMixpanelEvent({
      name: MixpanelEventName.PAGE_VIEW,
    });
  }, [logMixpanelEvent]);

  useEffect(() => {
    handleRouteChange();
    // Disabling exhaustive dependencies to only run when the URL changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
}
