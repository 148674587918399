"use client";

import "@phiaco/phia-ui/dist/styles.css";
import "./_css/globals.css";
import "cropperjs/dist/cropper.css";
import BackToTop from "@/components/BackToTop";
import Footer from "@/components/Footer";
import Header from "@/components/Header/Header";
import LayoutSpacer from "@/components/LayoutSpacer";
import NoSSR from "@/components/NoSSR";
import {
  PHIA_COOKIE_ID,
  PHIA_COOKIE_MIXPANEL_DEVICE_ID,
  PHIA_COOKIE_MIXPANEL_DISTINCT_ID,
} from "@/constants";
import AuthProvider from "@/context/AuthContext";
import RouteListener from "@/context/RouteListener";
import { SearchProvider } from "@/context/SearchContext";
import StatsigProvider from "@/context/StatsigProvider";
import { useInternalEmployeeStatus } from "@/hooks/useInternalEmployeeStatus";
import {
  EXTENSION_IFRAME_ROUTES,
  routes,
  ROUTES_WITHOUT_CHAT,
  ROUTES_WITHOUT_LAYOUT,
} from "@/routes";
import { init as initFullStory } from "@fullstory/browser";
import { ToastProvider } from "@phiaco/phia-ui";
import { matchRoute } from "@phiaco/phia-ui/dist/util";
import { GoogleOAuthProvider } from "@react-oauth/google";
import classNames from "classnames";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import dynamic from "next/dynamic";
import { Inter } from "next/font/google";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import HubspotTrackingCode from "./_components/HubspotTrackingCode";
import UTMParams from "./_components/UTMParams";

const INTER = Inter({ subsets: ["latin"] });
const DEFAULT_COOKIE_OPTIONS: Cookies.CookieAttributes = {
  domain: ".phia.com",
  path: "/",
  secure: true,
  sameSite: "Lax",
};

const BranchIOInit = dynamic(() => import("./_components/BranchIOInit"));

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname() ?? "";

  // Set the state to determine internal employee status when user is unauthed
  useInternalEmployeeStatus();

  const includeLayout =
    !matchRoute(pathname, ROUTES_WITHOUT_LAYOUT) &&
    !pathname.startsWith(routes.REFERRAL_SIGNUP);

  const includeChat = !matchRoute(pathname, ROUTES_WITHOUT_CHAT);

  // If the route is an extension iframe route, we don't want to avoid using an auth context.
  const isExtensionIframeRoute = matchRoute(pathname, EXTENSION_IFRAME_ROUTES);

  useEffect(() => {
    // Identify logged in user for Mixpanel, Sentry, and FullStory
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN ?? "", {
      debug: process.env.NODE_ENV === "development",
      persistence: "localStorage",
    });

    Cookies.set(
      PHIA_COOKIE_MIXPANEL_DEVICE_ID,
      mixpanel.get_property("$device_id"),
      DEFAULT_COOKIE_OPTIONS
    );
    Cookies.set(
      PHIA_COOKIE_MIXPANEL_DISTINCT_ID,
      mixpanel.get_property("distinct_id"),
      DEFAULT_COOKIE_OPTIONS
    );

    let phiaId = Cookies.get(PHIA_COOKIE_ID);
    if (!phiaId) {
      phiaId = crypto.randomUUID();
      Cookies.set(PHIA_COOKIE_ID, phiaId, DEFAULT_COOKIE_OPTIONS);
    }

    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      initFullStory({
        orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID ?? "",
      });
    }
  }, []);

  return (
    <NoSSR>
      <main className={classNames(INTER.className, "antialiased")}>
        <GoogleOAuthProvider
          clientId={
            process.env.NEXT_PUBLIC_FIREBASE_SERVICE_ACCOUNT_CLIENT_ID ?? ""
          }>
          <ToastProvider>
            {isExtensionIframeRoute ? (
              <StatsigProvider>{children}</StatsigProvider>
            ) : (
              <AuthProvider>
                <StatsigProvider>
                  <RouteListener>
                    <SearchProvider>
                      {includeLayout && <LayoutSpacer />}
                      {includeLayout && <Header />}
                      {children}
                      {includeLayout && <Footer />}
                      <BackToTop />
                    </SearchProvider>
                  </RouteListener>
                </StatsigProvider>
              </AuthProvider>
            )}
          </ToastProvider>
        </GoogleOAuthProvider>
      </main>
      <>
        {includeChat && <HubspotTrackingCode />}
        <UTMParams defaultCookieOptions={DEFAULT_COOKIE_OPTIONS} />
        <BranchIOInit />
      </>
    </NoSSR>
  );
}
