"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhiaNameLogo = void 0;
var React = __importStar(require("react"));
var tailwind_merge_1 = require("tailwind-merge");
var PhiaNameLogo = function (_a) {
    var className = _a.className, _b = _a.width, width = _b === void 0 ? 45 : _b, _c = _a.height, height = _c === void 0 ? 24 : _c, props = __rest(_a, ["className", "width", "height"]);
    return (React.createElement("svg", __assign({ className: (0, tailwind_merge_1.twMerge)("fill-black opacity-20 ", className), width: width, height: height, viewBox: "0 0 45 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M22.5175 18.8703C21.1434 18.8703 21.1193 17.5631 21.4568 16.1372L22.8309 10.1244C23.1442 8.81722 23.3853 7.51008 22.4211 7.51008C20.6186 7.51008 17.5706 12.6594 16.31 18.5309C16.3032 18.5624 16.275 18.5851 16.2423 18.5851H13.8764C13.8325 18.5851 13.7998 18.5453 13.8088 18.5031L17.0938 3.08959C17.4026 1.61434 17.1732 1.08506 16.3824 0.865217C16.3632 0.85989 16.3519 0.839832 16.3582 0.82121L16.382 0.750811C16.3899 0.727451 16.41 0.710121 16.4345 0.7055L20.1791 0H20.1904C20.2343 0 20.267 0.0398474 20.2579 0.0821653L17.612 12.367C17.6034 12.4071 17.6619 12.4252 17.6789 12.3878C19.4706 8.45027 21.4958 5.84646 23.9639 5.84646C25.9165 5.84646 25.9164 7.84281 25.3379 10.3145L24.0121 15.9708C23.8915 16.4699 23.7469 17.1116 24.1326 17.1116C24.4078 17.1116 24.9717 16.7006 25.7457 15.7754C25.7706 15.7456 25.816 15.7417 25.8454 15.7674L25.9629 15.8703C25.9876 15.8919 25.9931 15.928 25.9763 15.9559C24.866 17.8015 23.6637 18.8703 22.5175 18.8703Z" }),
        React.createElement("path", { d: "M31.2903 4.03026C30.3984 4.03026 29.6993 3.34104 29.6993 2.4617C29.6993 1.60612 30.3984 0.9169 31.2903 0.9169C32.1581 0.9169 32.8572 1.60612 32.8572 2.4617C32.8572 3.34104 32.1581 4.03026 31.2903 4.03026Z" }),
        React.createElement("path", { d: "M28.3735 18.8603C26.9512 18.8603 27.0476 17.2917 27.3128 16.1272L28.9038 8.73594C29.0243 8.21309 29.1448 7.59517 28.7591 7.59517C28.4973 7.59517 28.0505 7.99327 27.4326 8.70631C27.4041 8.73914 27.3522 8.73818 27.3253 8.70414L27.2351 8.59015C27.2179 8.56843 27.2159 8.53839 27.2297 8.5145C28.2102 6.82894 29.3777 5.83648 30.4466 5.83648C31.7965 5.83648 31.7483 7.16738 31.4591 8.56958L29.868 15.9608L29.8634 15.9822C29.7889 16.324 29.635 17.0303 30.0127 17.0303C30.3476 17.0303 30.9412 16.5063 31.5826 15.7643C31.6069 15.7362 31.6497 15.7321 31.6789 15.7553L31.8145 15.863C31.8411 15.8842 31.8478 15.9217 31.8304 15.9507C30.7251 17.7933 29.5399 18.8603 28.3735 18.8603Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M39.9957 14.4622C38.5131 17.495 36.9584 18.8562 35.0761 18.8562C33.5557 18.8562 32.4953 17.5484 32.4953 15.218C32.4953 10.914 35.5844 5.82527 40.1671 5.82527C41.3206 5.82527 43.4669 6.53118 44.066 7.18407C44.0935 7.21397 44.1024 7.25499 44.0939 7.29444L42.2292 15.9593C42.1545 16.3258 41.9719 17.0303 42.3576 17.0303C42.6926 17.0303 43.3576 16.5063 43.999 15.7643C44.0233 15.7362 44.066 15.7321 44.0952 15.7553L44.2308 15.863C44.2574 15.8842 44.2641 15.9217 44.2467 15.9507C43.1414 17.7933 41.9563 18.8603 40.7898 18.8603C40.416 18.8603 40.1462 18.7515 39.9564 18.5699C39.424 18.0606 39.5208 16.979 39.7163 16.1205L40.0658 14.4835C40.0746 14.4429 40.0126 14.4242 39.9957 14.4622ZM40.964 9.95623C40.1198 14.1414 37.7644 17.0451 36.1725 17.0451C35.3283 17.0451 34.7528 16.3693 34.7528 14.6572C34.7528 10.8526 37.2737 6.56638 39.733 6.56638C41.4729 6.56638 41.2224 8.74626 40.964 9.95623Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.5919 5.83774C12.3907 5.83774 13.7303 7.76264 13.7303 10.4955C13.7303 14.583 10.7876 18.8605 6.2668 18.8605C5.87793 18.8605 5.12114 18.7957 4.61614 18.6248C4.57823 18.6119 4.53709 18.6344 4.52889 18.6733C4.52889 18.6734 4.52889 18.6733 4.52888 18.6734C4.52878 18.674 4.51676 18.7462 3.93025 21.5269C3.60898 23.0502 3.40929 23.7152 4.24182 23.7793C4.26636 23.7831 4.28424 23.8058 4.28023 23.8302L4.25819 23.9638C4.25476 23.9847 4.23662 24 4.21535 24H0.150722C0.12393 24 0.103534 23.9761 0.107887 23.9499L0.136406 23.8057C0.140078 23.7835 0.157886 23.7663 0.180131 23.7624C0.934528 23.6584 0.974549 23.3448 1.36706 21.5392C2.39068 16.8304 3.20921 13.0699 4.21578 8.44836C4.22197 8.41993 4.19302 8.39661 4.16636 8.40863C3.75082 8.59591 3.3262 8.80866 2.88229 9.04687C2.8435 9.06769 2.7951 9.04911 2.78064 9.00773L2.6781 8.71431C2.66809 8.68568 2.67751 8.65388 2.70157 8.63525C3.25241 8.2087 3.80656 7.82552 4.3714 7.48571C4.40543 7.46524 4.42948 7.43171 4.43764 7.39301L4.76802 5.82492C4.77387 5.79713 4.79312 5.77398 4.81949 5.76302L7.4076 4.68728C7.43989 4.67386 7.47406 4.70196 7.46683 4.73597L7.16414 6.16036C7.15858 6.18651 7.18304 6.20914 7.20893 6.20192C8.02714 5.97392 8.84379 5.83774 9.5919 5.83774ZM11.2661 10.7094C11.2661 14.3929 9.18082 18.1251 6.72133 18.1251C4.98883 18.1251 5.03549 16.2626 5.28603 15.082C6.89164 7.51579 6.90627 7.44689 6.90628 7.44686C6.90628 7.44686 6.90628 7.44686 6.90628 7.44686C6.91165 7.42118 6.93089 7.40047 6.95628 7.39328C7.51326 7.23537 7.97709 7.16747 8.44402 7.16747C10.3577 7.16747 11.2661 8.38051 11.2661 10.7094Z" })));
};
exports.PhiaNameLogo = PhiaNameLogo;
exports.default = exports.PhiaNameLogo;
