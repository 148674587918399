export const PLATFORM = "marketplace-web";

export const MAX_PAGE_WIDTH = 1440;

export const zIndex = {
  MODAL_OVERLAY: 130,
  SIDEBAR_OVERLAY: 120,
  PRODUCT_MODAL: 110,
  HEADER: 100,
  SEARCH_OVERLAY: 90,
  HEADER_CONTAINER: 80,
  CATEGORY_MENU: 85,
  SEARCH_RESULTS_SIDEBAR: 40,
  SORT_AND_FILTER_DROPDOWNS: 30,
  SEARCH_FEEDBACK: 20,
  PRODUCT_CARD_OVERLAY: 10,
};

export const localStorageKeys = {
  RECENT_SEARCH: "recentSearch",
};

export const BETA_ACCESS_COOKIE = "beta-access-email";
export const GIVEAWAY_TOOLTIP_COOKIE_NAME = "phia-giveaway-tooltip";

export const EXTENSION_URL =
  "https://chromewebstore.google.com/u/0/detail/phia/ehoknmhmadiboejdbinglmbnlghnbldc";

export const FOUNDERS_CALENDAR_LINK = "https://zcal.co/i/8B7WMTDG";

export const VERCEL_BLOB_URL =
  "https://zbypaa9oazexo3ul.public.blob.vercel-storage.com";

export const PHIA_APP_DEEP_LINK =
  "https://app.phia.com/post-install-onboarding";

export enum EXTENSION_RELAY_NAMES {
  USER_PREFERENCES = "preferences",
}

export const WEBSITE_REPORT_BUG_SUBJECT =
  "[Website] Contact and Support Report";
export const WEBSITE_FEEDBACK_SUBJECT = "[Website] Feedback Report";

export const PHIA_DOT_COM = "phia.com";

export const SEARCH_PARAMS = {
  SHARED_SEARCH_ID: "sharedSearchId",
};

export const PHIA_COOKIE_ID = "phia_id";

export const PHIA_COOKIE_MIXPANEL_DEVICE_ID = "mixpanel-device-id";
export const PHIA_COOKIE_MIXPANEL_DISTINCT_ID = "mixpanel-distinct-id";
